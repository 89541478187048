.campaign-table {
  max-width: 450px;
}

.campaign-table
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container
  > .ant-table-body {
  overflow-y: auto !important;
}
