@import "~antd/dist/antd.css";

.app {
  height: 100%;
}

.app-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-table-thead th {
  background: #e5e9f2 !important;
  color: #47525e !important;
  font-weight: bold !important;
}

.ant-card {
  border-radius: 15px !important;
}

.ant-card-actions {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.ant-divider-horizontal {
  margin: 12px 0 !important;
}
