.background-details {
    padding: 32px 0;
    justify-content: space-evenly;
}

.footer-button {
    color: #8190A5 !important;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.hidden-component {
    width: 0 !important;
    height: 0 !important;
}
