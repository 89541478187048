.campaign-details-body > .ant-card > .ant-card-body {
  padding: 0;
}

.campaign-details-title {
  background: #e5e9f2;
  padding: 12px;
  margin-bottom: 15px;
}

.campaign-details-title h1 {
  color: #47525e;
  margin: 0;
}

.campaign-char {
  margin-bottom: 15px;
}

.campaign-char-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
