.character-details-wrapper {
  margin: auto;
  max-height: 100%;
  width: 100%;
  max-width: 600px;
  overflow: auto;
}

.character-details-card {
  min-width: 320px;
  max-width: 600px;
}

.character-details-card,
.character-details-card-footer,
.character-details-card-header,
.character-details-card > .ant-card-body,
.character-details-card-header h1 {
  color: #47525e;
}

.character-details-card > .ant-card-actions {
  background: #e5e9f2;
  padding: 0 12px;
  position: sticky;
  bottom: 0;
}

.character-details-card-header {
  padding: 24px;
  background: #e5e9f2;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.character-details-card-header h1 {
  margin-bottom: 0;
}

.character-details-card-footer-icon {
  width: 40px;
  padding: 8px 0;
}

.character-details-card-footer > .ant-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.character-details-card
  > .ant-card-head
  > .ant-card-head-wrapper
  > .ant-card-head-title {
  padding-bottom: 0;
}
