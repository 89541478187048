.dark-grey {
  width: 165px;
}

.dark-grey h3,
.dark-grey div {
  color: #47525e;
}

.dark-grey h3 {
  font-weight: bold;
}

.campaign-char-attr-icon {
  width: 40px;
}

.campaign-char-attr {
  width: 45px;
}

.campaign-char-space {
  justify-content: space-around;
  border-radius: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
}
