.w-100 {
  width: 100%;
}

.notes-footer {
  padding-top: 12px;
  text-align: end;
}

.notes-footer > .ant-btn {
  margin-left: 8px;
}

.notes-text-area {
  border-radius: 20px !important;
}
