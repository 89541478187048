.create-character-wrapper {
  margin: auto;
  max-height: 100%;
  width: 100%;
  max-width: 600px;
  overflow: auto;
}

.create-character-card {
  min-width: 320px;
  width: 100%;
  margin: auto;
}

.create-character-card > .ant-card-actions {
  position: sticky;
  bottom: 0;
}

.return-button {
  color: #8190a5 !important;
  padding: 0 !important;
}

.create-character-step {
  color: #8190a5;
  text-align: end;
  align-self: center;
}
