.input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-container:focus-within label {
    padding-left: 3px;
    padding-right: 3px;
    transform: translate(0, 9px) scale(0.8);
    color: #79b1ff;
}

.input-container .filled {
    padding-left: 3px;
    padding-right: 3px;
    transform: translate(0, 9px) scale(0.8);
}

.light {
    background: white !important;
}

.dark {
    background: #303030 !important;
}

.input-container label {
    z-index: 1;
    margin-top: -14px;
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #DCDCDC;
    font-size: 14px !important;
    line-height: 1;
    left: 12px;
}

.input-container input {
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input:focus {
    box-shadow: 0 0 0 2px #79b1ff;
}
