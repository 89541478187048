.sign-up-card {
    max-width: 400px;
}

.sign-up-card-header-icon {
    width: 100px;
}

.sign-up-card > .ant-card-head > .ant-card-head-wrapper {
    text-align: center;
}
