.death-row-details {
  padding: 24px;
}

.death-row-button {
  width: 120px;
}

.death-row-details > .ant-divider,
.death-col-details {
  padding-bottom: 10px;
}
